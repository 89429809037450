
.vendor-page {
}

.hero {
}

.user-avatar {
  display: flex;
  align-items: center;
  gap: var(--margin-m);

  &__image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 35px;
    width: 35px;
    flex: 0 0 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-xl);
    background-color: var(--c-gray-1);
    border: 1px solid var(--c-gray-2);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) inset;
    color: transparent;

    &--no-image {
      background-image: none !important;

      .user-avatar__image-letter {
        display: flex;
      }
    }

    &__image-letter {
      display: none;
      color: var(--c-alt);
      font-weight: bold;
    }
  }

  &__name {
    font-weight: 400;
    font-size: var(--font-m);
    margin: 0;
    padding: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      margin: 0;
    }
  }

  &--large,
  &--l {
    .user-avatar__image {
      height: 55px;
      width: 55px;
      flex: 0 0 55px;
      font-size: var(--font-2xl);
    }

    .user-avatar__name {
      font-size: var(--font-xl);
      font-weight: 600;
    }
  }

  &--xl {
    .user-avatar__image {
      height: 75px;
      width: 75px;
      flex: 0 0 75px;
      font-size: calc(var(--font-2xl) * 1.2);
    }

    .user-avatar__name {
      font-size: calc(var(--font-2xl) * 1.2);
      font-weight: 600;
    }
  }
}

.vendor-main-content {
  display: flex;
  gap: var(--margin-max);
  align-items: stretch;
  justify-content: space-between;
}

.side-col {
  flex: 0 0 300px;
}

.product {
  height: 300px;
  width: 240px;
}
