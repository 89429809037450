
.offline-checkout-explain {
}
.offline-checkout-explain {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.order-area {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.cart-container {
  max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 500px;
    height: auto;
  }

  .cart {
    width: 360px;
    position: sticky;
    top: 80px;
    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      max-width: 100%;
      width: 500px;
    }
  }
}
