
// TODO: mixin imports, especially directional
/*

$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
 */

.logo {
  flex: auto;
  height: 100px;
  width: auto;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: var(--margin-xl);

  @media (max-width: 767px) {
    height: 60px;
    width: auto;
    background-size: auto 60%;
    margin-bottom: var(--margin-m);
  }
}

.main-title {
  margin-bottom: var(--margin-l);

  @media (max-width: 767px) {
    font-size: var(--font-2xl);
    margin-bottom: var(--margin-xl);
  }
}

.login-footer-links-inner {
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.link-wrap {
  @media (max-width: 767px) {
    margin-bottom: var(--margin-m);
  }

  &:last-of-type {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}
.login-component {
  max-width: calc(90vw - 60px);
  width: 550px;
  flex: 550px;

  @media screen and (max-width: 640px) {
    max-width: calc(90vw - 60px);
    width: 80vw;
    flex: 80vw;
  }

  @media screen and (max-width: 400px) {
    max-width: calc(90vw - 20px);
    width: 90vw;
    flex: 90vw;
  }

  .uk-card-body {
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
  }

  .uk-logo {
    display: inline-block;
  }

  .login-footer-links {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    .link-wrap {
      @media screen and (max-width: 640px) {
        flex: 100%;
      }
    }
  }
}
